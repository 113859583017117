import { useMetadata } from '@/api/hooks/useMetadata'
import { useReport } from '@/api/hooks/useReport'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal from '@/components/Modal/Modal'
import Toolbar from '@/components/Toolbar/Toolbar'
import FormLoader from '@/components/ui/form/FormLoader'
import { useProjectStore } from '@/stores/projectStore'
import { Input } from '@/ui/components/Field/Input'
import { SelectInput } from '@/ui/components/Field/Select'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { formatToAPI, formatToReport } from '@/utils/helpers/dates.helpers'
import { addMinutes, endOfWeek, format, startOfWeek } from 'date-fns'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import ReportDateSelector from './ReportDateSelector'
import { printPDF } from '@/utils/func/print'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'

type ReportModalProps = {
  open: boolean
  report: IReport
  onClose: () => void
}

const ReportView: React.FC<ReportModalProps> = ({ report, open, onClose }) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const nodes = useProjectStore((state) => state.nodes)
  const [parent, setParent] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [toggle, setOpen] = useState<boolean>(false)
  const [selection, setSelection] = useState<{
    startDate: any
    endDate: any
    key: string
  }>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(addMinutes(new Date(), -30)),
    key: 'selection'
  })
  const [name, setName] = useState<string>('')
  const intl = useIntl()
  const { metadata } = useMetadata()

  const handleNameChange = (e) => setName(e?.target?.value)
  const handleParentChange = (e) => setParent(e?.target?.value)
  const handleTypeChange = (e) => setType(e?.target?.value)

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500)
  }, [])

  useEffect(() => {
    return () => debouncedNameResponse.cancel()
  }, [])

  const { data, isLoading } = useReport({
    reportId: report.report_id,
    params: {
      start: formatToAPI(selection.startDate),
      end: formatToAPI(selection.endDate),
      type_uid: type,
      root_layer: parent
    }
  })

  const translations = {
    type_name: intl.formatMessage({ id: 'report-type_name' }),
    name: intl.formatMessage({ id: 'report-name' }),
    parent: intl.formatMessage({ id: 'report-parent' })
  }

  const columns = Object.keys(report.columns).filter((v) =>
    ['type_name', 'name', 'parent'].includes(v)
  )

  const reportItems = data?.report || []

  const formatData = useMemo(() => {
    return data
      ? reportItems.map((item) => {
          const nodeType = nodes.find((node) => node.id == item.parent)

          return {
            ...item,
            parent: nodeType?.name
          }
        })
      : []
  }, [reportItems])

  const pdfCols = useMemo(
    () => columns.map((col) => ({ header: translations[col], dataKey: col })),
    [columns]
  )

  return (
    <Modal isOpen={open} onClose={onClose}>
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: report.name })
          })}
        </Title>
        <ExportWrapper>
          <ReportButton
            onClick={() =>
              printPDF({
                name: report.name,
                columns: pdfCols,
                body: formatData
              })
            }
          >
            PDF
          </ReportButton>
          <CSVLink
            data={formatData || ''}
            headers={columns.map((col) => ({
              label: translations[col],
              key: col
            }))}
            separator={';'}
            filename={`${report.name} ${formatToReport(new Date())}`}
          >
            <ReportButton>CSV</ReportButton>
          </CSVLink>
          <Close color="#000" onClick={onClose} />
        </ExportWrapper>
      </Header>

      <Toolbar>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('start-datetime')}</Toolbar.BlackLabel>
          <Input
            $fullWidth
            value={`${format(
              selection.startDate,
              'dd.MM.yyyy HH:mm'
            )} - ${format(selection.endDate, 'dd.MM.yyyy HH:mm')}`}
            onClick={() => setOpen(true)}
          />
          <ReportDateSelector
            open={toggle}
            setOpen={setOpen}
            selection={selection}
            setSelection={setSelection}
          />
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('location')}</Toolbar.BlackLabel>
          <SelectInput $fullWidth value={parent} onChange={handleParentChange}>
            <option value="">{translate('all-levels')}</option>
            {nodes.map((node) => (
              <option key={node.id} value={node.id}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('object-types')}</Toolbar.BlackLabel>
          <SelectInput $fullWidth value={type} onChange={handleTypeChange}>
            <option value="">{translate('all')}</option>
            {metadata?.nodes?.map((node) => (
              <option key={node.uid} value={node.uid}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
      </Toolbar>

      <Grid style={{ position: 'relative' }}>
        <Grid.RowHeader $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
          {columns.map((col) => (
            <Grid.Item key={col}>{translations[col]}</Grid.Item>
          ))}
        </Grid.RowHeader>

        {reportItems.length ? (
          reportItems
            .slice((currentPage - 1) * 20, currentPage * 20)
            .map((spot) => (
              <ReportItem key={spot.id} columns={columns} item={spot} />
            ))
        ) : (
          <NotFound>{translate('no-results')}</NotFound>
        )}

        <FormLoader isLoading={isLoading} />
      </Grid>

      <Pagination
        inverse
        currentPage={currentPage}
        total={reportItems.length || 0}
        handlePageChange={setCurrentPage}
      />
    </Modal>
  )
}

export default ReportView

const ReportItem = ({ item, columns }) => {
  // console.log(item, columns)
  const nodes = useProjectStore((state) => state.nodes)

  const cols = columns
    .map((column) => {
      let data = item[column]

      if (!data) return null

      if (column == 'parent') {
        const uid = data
        const nodeType = nodes.find((node) => node.id == data)
        data = nodeType?.name || uid
      }

      return {
        key: column,
        value: data
      }
    })
    .filter((v) => v)

  return (
    <Grid.Row $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
      {cols.map(({ key, value }) => (
        <Grid.Item key={key}>{value}</Grid.Item>
      ))}
    </Grid.Row>
  )
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
        margin-top: 20px;
    `}
`

export const ReportButton = styled.button`
  border: 2px solid #079dac;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: #079dac;
  padding: 1.2rem 2rem;
  outline: none;
  background: transparent;
  cursor: pointer;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`
