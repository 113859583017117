import { MailService } from '@/api/services/mail.service'
import { useToast } from '@/components/shared/toast/useToast'
import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { FormControls } from '@/components/ui/form/FormControls'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { translate } from '@/i18n'
import { useUserStore } from '@/stores/userStore'
import { InputField } from '@/ui/components/Field/Input'
import { SelectField } from '@/ui/components/Field/Select'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { useMaintenanceDialogStore } from './MaintenanceModal'

const d = new Date()
const offset = d.getTimezoneOffset()
export const maintenanceTypes = [
	{ value: '1', label: 'Уборка', alias: 'cleaning' },
	{ value: '2', label: 'Техническое облуживание', alias: 'maintenance' },
]

const mergeMessage = (
	seat: string,
	created: string,
	reason: string,
	message: string,
) =>
	[
		`Номер места: ${seat}`,
		`Запросил: ${created}`,
		`Причина: ${reason}`,
		`Комментарий: ${message}`,
	].join('.<br />')

const MaintenanceForm: React.FC = () => {
	// data selectors
	const onClose = useMaintenanceDialogStore((state) => state.onClose)
	const seat = useMaintenanceDialogStore((state) => state.seat)
	const employee = useMaintenanceDialogStore((state) => state.employee)
	const user = useUserStore((state) => state.user)
	const intl = useIntl()

	const maintenance = useSettingsSelector(
		(settings) => settings.maintenance,
		[],
	)

	const { enqueueToast } = useToast()

	const { mutate } = useMutation(
		'maintenance-user',
		(data: any) =>
			MailService.createMaintenance({
				subject:
					'Заявка на обслуживание: ' +
						maintenance.find((m) => m.type === data.type)?.type ||
					'Обслуживание',
				message: mergeMessage(
					data.seat,
					user ? `${user?.display}` : '',
					maintenance.find((m) => m.type === data.type)?.type || 'Обслуживание',
					data.more || '-',
				),
				recipient: data.recipient,
			}),
		{
			onSuccess(data) {
				onClose()
				enqueueToast('Отправлено', { variant: 'success' })
			},
			onError(data: any) {
				enqueueToast('Не удалось отправить', { variant: 'error' })
			},
		},
	)

	const maintenanceOptions =
		maintenance.map((data, idx) => ({
			label: data.type,
			value: idx + '_' + data.recipient,
		})) || []

	const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
		setSubmitting(true)
		const [idx, email] = values.type.split('_') || ''

		const type = maintenanceOptions.find((opt) => opt.value == values.type)
			?.label

		mutate({
			seat: values.seat,
			employee: values.employee,
			subject: values.subject,
			more: values.more,
			type,
			recipient: email,
		})

		setSubmitting(false)
	}

	return (
		<Formik
			onSubmit={handleFormSubmit}
			enableReinitialize
			initialValues={{
				seat,
				employee,
				type: maintenanceOptions?.at(0)?.value,
			}}
		>
			{({ handleSubmit, values, isSubmitting }) => (
				<form onSubmit={handleSubmit}>
					<FormContainer>
						<Grid container>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('place')}</FormLabel>
								<Field
									name="seat"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'place' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('employee')}</FormLabel>
								<Field
									name="employee"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'employee' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('service-type')}</FormLabel>
								<Field
									name="type"
									$fullWidth
									required={true}
									component={SelectField}
									options={maintenanceOptions}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<FormLabel>{translate('more-info')}</FormLabel>
								<Field
									name="more"
									$fullWidth
									placeholder={intl.formatMessage({ id: 'describe' })}
									component={InputField}
									textarea
									rows={10}
								/>
							</Grid>
						</Grid>
					</FormContainer>

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
								{translate('send')}
							</PrimaryButton>
							<DefaultButton $fullWidth type="button" onClick={onClose}>
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</form>
			)}
		</Formik>
	)
}

const FormContainer = styled.div`
	padding: 1rem 0;
	max-height: 100%;
`

export default MaintenanceForm

const ControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`
